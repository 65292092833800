// Yup TypeScript integration:
// https://github.com/jquense/yup#typescript-integration
//
// Yup enum handling:
// https://github.com/jquense/yup/issues/1013#issuecomment-833886607
//
// assert string is valid enum value
// https://dev.to/mandraketech/typescript-string-enums-the-easy-way-1ke4
// or simpler:
//   const myEnum = val as MyEnumType;
import * as yup from "yup";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { ComponentType } from "react";
import { SvgIconProps } from "@mui/material";
export type { IconDefinition };
export type SvgIcon = ComponentType<SvgIconProps>;

export enum PlanType {
  GUEST = "GUEST",
  GUEST_V2 = "GUEST_V2",

  IMPACT = "IMPACT",
  FAME = "FAME",
  VIP = "VIP",
  COMBO = "COMBO",
}

// https://docs.google.com/document/d/1tIRTunLZcXJnB86w3p_nRHBBHtZQaTp9IV_YpwXXUoM/edit
export enum ComboType {
  // 1.9x rev + 1x likes
  REVENUE = "REVENUE",

  // 1.6x rev + 1.15x likes
  BALANCE = "BALANCE",

  // 1.2x rev + 1.45x likes
  MARKETING = "MARKETING",
}

export enum BusinessType {
  RESTAURANT = "RESTAURANT",
  SPA = "SPA",
  SALON = "SALON",
  OTHER = "OTHER",
}

export enum PlanTier {
  ENTRY = "ENTRY",
  STANDARD = "STANDARD",
  PREMIUM = "PREMIUM",
  STAR = "STAR",
  FREE = "FREE",
  FREEP = "FREE+",
}

export enum ReviewPlatform {
  YELP = "YELP",
  GOOGLE_MAP = "GOOGLE_MAP",
}

export enum SocialMedia {
  TIKTOK = "TIKTOK",
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  YOUTUBE = "YOUTUBE",
  XIAO_HONG_SHU = "XIAO_HONG_SHU",
}

export enum PlanStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
}

export enum ImgSizes {
  xs = "xs",
  md = "md",
  lg = "lg",
  og = "og",
}

export const userSchema = yup.object({
  username: yup.string().trim().defined(),
  attributes: yup.object({
    email: yup.string().trim().defined().email(),
    phone_number: yup.string().defined(),
    "custom:profile_complete": yup.string().optional(),
  }),
});
export interface User extends yup.InferType<typeof userSchema> {}

export const merchantInfoSchema = yup.object({
  id: yup.string().trim().optional(),
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .defined()
    .ensure()
    .min(2, "First name is at least 2 letters long"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .defined()
    .ensure()
    .min(2, "Last name is at least 2 letters long"),
});
export interface MerchantInfo
  extends yup.InferType<typeof merchantInfoSchema> {}

export const addressSchema = yup.object({
  address_line1: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid address"),
  city: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid city"),
  state: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid state"),
  zipcode: yup
    .string()
    .defined()
    .ensure()
    .matches(/^\d{5}(-\d{4})?$/, "Please enter a valid zipcode"),
  neighborhood: yup.string().trim(),
});
export interface Address extends yup.InferType<typeof addressSchema> {}

export const storeSchema = yup.object({
  id: yup.string().trim().optional(),

  // Obtain from User
  email: yup.string().trim().defined().ensure(),
  phone_number: yup.string().defined().ensure(),
  // Obtain from MerchantInfo
  merchant_id: yup.string().trim().defined().ensure(),
  contact_first_name: yup.string().trim().defined().ensure(),
  contact_last_name: yup.string().trim().defined().ensure(),
  business_name: yup
    .string()
    .trim()
    .defined()
    .ensure()
    .min(2, "Please enter a valid business name"),
  business_type: yup
    .mixed<BusinessType>()
    .oneOf(Object.values(BusinessType))
    .defined()
    .default(BusinessType.RESTAURANT),
  custom_business_type: yup
    .string()
    .optional()
    .default("")
    .when(["business_type"], (business_type, schema) => {
      if (business_type === BusinessType.OTHER) {
        return schema.required("Please specify your custom business type");
      }
      return schema;
    }),
  address: addressSchema.defined(),
  // Mapping:
  //   1: <10
  //   2: 10-30
  //   3: 30-60
  //   4: 60+
  price_range_per_person: yup.number().integer().min(1).max(4).default(2),

  image_s3_paths: yup.array().of(yup.string()).default([]),

  tags: yup.array().of(yup.string()).default([]),
});
export interface Store extends yup.InferType<typeof storeSchema> {}

// Schema for the CreatePlanWithType form
export const planFormSchema = yup.object({
  plan_name: yup
    .string()
    .defined()
    .min(2, "Plan name should be at least of length 2"),
  max_contracts_per_month: yup.number().min(0).defined(),
  // Max reimbursement for INFLUENCER.
  max_reimbursement_per_bill: yup.number().min(0).defined(),
  social_medias: yup
    .array()
    .of(yup.mixed<SocialMedia>().oneOf(Object.values(SocialMedia)).defined())
    .defined(),
  review_platforms: yup
    .array()
    .of(
      yup.mixed<ReviewPlatform>().oneOf(Object.values(ReviewPlatform)).defined()
    )
    .defined(),
  tiers: yup
    .array()
    .of(yup.mixed<PlanTier>().oneOf(Object.values(PlanTier)).defined())
    .defined()
    .min(1, "Please select at least 1 tier"),
  store_ids: yup
    .array()
    .of(yup.string().defined())
    .defined()
    .min(1, "Please select at least 1 store"),
  notes: yup.string().defined().default(""),
  combo_type: yup.mixed<ComboType>().oneOf(Object.values(ComboType)).optional(),
  combo_monthly_budget: yup.number().optional(),
  payment_method_id: yup.string().optional(),
});
export interface PlanForm extends yup.InferType<typeof planFormSchema> {}

//data sent to backend with _ for python
export interface PlanWithoutId {
  merchant_id: string;
  plan_name: string;
  plan_type: PlanType;
  max_contracts_per_month: number;
  // Max reimbursement for INFLUENCER.
  max_reimbursement_per_bill: number;
  social_medias: SocialMedia[];
  review_platforms: ReviewPlatform[];
  tiers: PlanTier[];
  stores: Store[];
  combo_type?: ComboType;
  combo_monthly_budget?: number;
  notes?: string;

  // Payment method id. For new plans, this field should always be populated.
  // For old plans, this field can be undefined. In that case, we will look for
  // the default card attached to the user.
  payment_method_id?: string;

  // Plan status, which is either paused or active
  status?: PlanStatus;
}

// Plan returned from backend
export interface Plan extends PlanWithoutId {
  id: string;
  visible: boolean;
  visible_last_updated: string;
}

export type Card = {
  brand: string;
  country: string;
  last4: string;
  exp_month: number;
  exp_year: number;
};

// "type": "us_bank_account",
// "us_bank_account": {
//   "account_holder_type": "individual",
//   "account_type": "checking",
//   "bank_name": "STRIPE TEST BANK",
//   "fingerprint": "FBIuxVrrYFJJcDrY",
//   "last4": "6789",
//   "routing_number": "110000000"
// }
export type USBankAccount = {
  account_holder_type: string;
  account_type: string;
  bank_name: string;
  fingerprint: string;
  last4: string;
  routing_number: string;
};

export type PaymentMethod = {
  id: string;
  type: "card" | "us_bank_account";
  card?: Card;
  us_bank_account?: USBankAccount;
};

export type Post = {
  id: string;
  plan_type: PlanType;
  store: Store;
  // Max reimbursement for INFLUENCER.
  max_reimbursement_per_bill: number;
  skip_receipt?: boolean;
};

export type Receipt = {
  image_s3_path: string;
};

export type Review = {
  platform: ReviewPlatform;
  image_s3_paths: string[];
  last_updated: string;
};

export type SocialMediaPost = {
  platform: SocialMedia;
  last_updated: string;
  image_s3_paths: string[];
  likes?: number;
};

export enum StatusType {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  DISPUTE = "DISPUTE",
  GIVE_UP = "GIVE_UP",
  REQUEST_INFO = "REQUEST_INFO",
  WAIT_APPROVAL = "WAIT_APPROVAL",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
}

export type Status = {
  status_type: StatusType;
  timestamp: string;
};

export type Contract = {
  id: string;
  // Example: 2022-05-03T19:17:04.488094
  created_timestamp: string;
  merchant_id: string;
  influencer_id: string;
  post: Post;
  subtotal?: number;
  receipts: Receipt[];
  reviews: Review[];
  social_medias: SocialMediaPost[];
  success?: boolean;
  last_updated: number;
  statuses: Status[];
  // tier selected by the influencer
  tier: PlanTier;
};

export const ImgFileSize = {
  xs: { maxHeight: 35, maxWidth: 35 },
  md: { maxHeight: 300, maxWidth: 300 },
  lg: { maxHeight: 1000, maxWidth: 1000 },
  og: { maxHeight: 0, maxWidth: 0 },
};

export type ProgressField = {
  completed: number;
  pending: number;
  target: number;
};

export type PlanProgress = {
  revenue: ProgressField;
  likes: ProgressField;
};
